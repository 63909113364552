<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5 ">
              <div class="">
                <div class="flex align-items-center ">
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">Institution Campuses</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-5 lg:col-4 ">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText outlined dense placeholder="Search" class="w-full" v-model="searchInput" />
              </span>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="page-section mb-3">
        <div class="flex justify-content-end">
          <Button
                label="Add New Campus"
                icon="pi pi-plus"
                @click="
                  openPageDialog(
                    {
                      page: 'institution_campuses/add',
                      url: `/institution_campuses/add`,
                    },
                    { persistent: true, closeBtn: true }
                  )
                "
                class="w-fullbg-primary"
              />
      </div>
      </section>
      
    </template>
    <section class="page-section ">
      <div class="container-fluid">
        <div class="grid ">
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente">
                Search
                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb :home="{ icon: 'pi pi-home', to: '/institution_campuses' }" :model="pageBreadCrumb" />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records">
                      <DataTable :lazy="true" :loading="loading" v-model:selection="selectedItems" :value="records"
                        dataKey="institution_campus_id" @sort="onSort($event)" class="" :showGridlines="false"
                        :rowHover="true" responsiveLayout="stack">
                        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                        <Column field="campus_code" header="Campus Code">
                          <template #body="{ data }">
                            {{ data.campus_code }}
                          </template>
                        </Column>
                        <Column field="campus_name" header="Campus Name">
                          <template #body="{ data }">
                            {{ data.campus_name }}
                          </template>
                        </Column>
                        <Column field="campus_description" header="Campus Description">
                          <template #body="{ data }">
                            {{ data.campus_description }}
                          </template>
                        </Column>
                         <Column field="is_accredited" header="Accredited">
                          <template #body="{ data }">
                            {{ data.is_accredited }}
                          </template>
                        </Column>
                        <Column headerStyle="width: 3em" headerClass="text-center">
                          <template #body="{ data }">
                            <Menubar class="p-0 text-center" ref="actionMenu" :model="getActionMenuModel(data)" />
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div v-show="loading" class="flex align-items-center justify-content-center text-gray-500 ">
                        <div>
                          <ProgressSpinner style="width:30px;height:30px;" />
                        </div>
                        <div>static</div>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <!-- <template v-if="!loading && !records.length">
                                            <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                                No record found
                                            </div>
                                        </template> -->
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <!-- <template v-if="showFooter">
                      <div class="">
                        <div class="flex justify-content-between">
                          <div class="flex-grow-0">
                            <div class="flex">
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <div v-if="!loading && totalPages > 1" class="flex  justify-content-center">
                              <div class="m-2">
                                <Button :disabled="pagination.page <= 1" label="Previous" @click="pagination.page--"
                                  icon="pi pi-arrow-left" />
                              </div>
                              <div class="m-2">
                                <Button :disabled="pagination.page >= totalPages" label="Next"
                                  @click="pagination.page++" icon="pi pi-arrow-right" icon-pos="right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template> -->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div class="flex justify-content-center flex-grow-0">
                              <div v-if="selectedItems.length" class="m-2">
                                <Button @click="deleteItem(selectedItems)" icon="pi pi-trash" class="p-button-danger"
                                  title="Delete Selected" />
                              </div>
                              <div
                                class="m-2"
                                v-if="exportButton && records.length"
                              >
                                <Button
                                  @click="
                                    (event) => $refs.exportMenu.toggle(event)
                                  "
                                  label="Export"
                                  class="py-3"
                                  title="Export"
                                  icon="pi pi-print"
                                />
                                <Menu
                                  ref="exportMenu"
                                  popup
                                  :model="pageExportFormats"
                                />
                              </div>
                            </div>
                            <div v-if="paginate && totalPages > 1" class="flex-grow-1 my-1">
                              <Paginator @page="(event) => {
                                  pagination.page = event.page + 1;
                                }
                                " :rows="pagination.rowsPerPage" :totalRecords="totalRecords">
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b>{{ recordsPosition }} of
                                      {{ totalRecords }}</b>
                                  </span>
                                </template>
                                <template #end>
                                  <Dropdown v-model="pagination.rowsPerPage"
                                    :options="[5, 10, 15, 20, 30, 40, 50, 100, 200]">
                                  </Dropdown>  
                                </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { ListPageMixin } from "../../mixins/listpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  name: 'listInstitutioncampusesPage',
  components: {
  },
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: 'institution_campus_id',
    },
    pageName: {
      type: String,
      default: 'institution_campuses',
    },
    routeName: {
      type: String,
      default: 'institution_campuseslist',
    },
    apiPath: {
      type: String,
      default: 'institution_campuses/index',
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: false,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
  },
  data() {
    return {
      pageExportFormats: [
        {
          label: "Excel",
          icon: "pi pi-file-excel text-green-500",
          command: () => {
            this.exportPageRecords("excel", "xlsx");
          },
        },
        {
          label: "Csv",
          icon: "pi pi-table text-green-200",
          command: () => {
            this.exportPageRecords("csv", "csv");
          },
        },
      ],
    }
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Institution Campuses"
      }
    },
    records: {
      get: function () {
        return this.$store.getters["institution_campuses/records"];
      },
      set: function (value) {
        this.$store.commit("institution_campuses/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["institution_campuses/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("institution_campuses/setCurrentRecord", value);
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("institution_campuses", ["fetchRecords", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        // {
        //   label: "Details",
        //   command: (event) => {
        //     this.openPageDialog(
        //       {
        //         page: "caps_programmes_list/view",
        //         url: `/caps_programmes_list/view/${data.caps_programme_id}`,
        //       },
        //       { closeBtn: true }
        //     );
        //   },
        //   icon: "pi pi-eye",
        // },
        {
          label: "Edit",
          command: (event) => {
            this.openPageDialog(
              {
                page: "institution_campuses/edit",
                url: `/institution_campuses/edit/${data.institution_campus_id}`,
              },
              { persistent: true, closeBtn: true }
            );
          },
          icon: "pi pi-pencil",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords
        }
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {
  },
  async created() {
  }
};
</script>
<style scoped></style>
